import Vue from "vue"
import App from "./App.vue"
import router from "./router"
import store from "./store"
import Meta from "vue-meta"
import VueAnalytics from "vue-analytics"

import { library } from "@fortawesome/fontawesome-svg-core"
import {
  faBars,
  faTimes,
  faPhone,
  faSearch,
  faUser,
  faShoppingCart,
  faChevronCircleDown,
  faChevronRight,
  faTruck,
  faHeadset,
  faAward,
  faRulerCombined,
  faCreditCard,
  faFolderOpen,
  faFilePdf,
  faMinusSquare,
  faPlusSquare,
  faChevronLeft,
  faPhoneAlt,
  faMapMarkerAlt,
  faEnvelope,
  faStar
} from "@fortawesome/free-solid-svg-icons"
import { faFacebook, faYoutube } from "@fortawesome/free-brands-svg-icons"
import { FontAwesomeIcon } from "@fortawesome/vue-fontawesome"

import "luminous-lightbox/dist/luminous-basic.min.css"

library.add(
  faBars,
  faTimes,
  faPhone,
  faFacebook,
  faYoutube,
  faSearch,
  faUser,
  faShoppingCart,
  faChevronCircleDown,
  faChevronRight,
  faTruck,
  faHeadset,
  faAward,
  faRulerCombined,
  faCreditCard,
  faFolderOpen,
  faFilePdf,
  faMinusSquare,
  faPlusSquare,
  faChevronLeft,
  faPhoneAlt,
  faMapMarkerAlt,
  faEnvelope,
  faStar
)
Vue.component("font-awesome-icon", FontAwesomeIcon)

const requireComponent = require.context("./components/elements", false, /[\w-]+\.vue$/)
requireComponent.keys().forEach((fileName) => {
  const componentConfig = requireComponent(fileName)
  const componentName = fileName.replace(/^\.\/(.*)\.\w+$/, "$1")
  Vue.component(componentName, componentConfig.default || componentConfig)
})

const isProd = process.env.NODE_ENV === "production"
Vue.config.productionTip = false
Vue.use(Meta)
Vue.use(VueAnalytics, {
  id: "UA-112511455-1",
  router,
  debug: {
    enabled: false,
    sendHitTask: isProd
  }
})

new Vue({
  router,
  store,
  render: (h) => h(App)
}).$mount("#app")
