import Vue from "vue"
import Vuex from "vuex"
import api from "@/services/api"

Vue.use(Vuex)

export default new Vuex.Store({
  state: {
    page: {},

    site_id: "hicklings",
    s3: "https://f003.backblazeb2.com/file/hicklingsmedia/",
    settings: null,
    main_pages: null,
    menu_pages: null,
    company_menu: null,
    services_menu: null,
    obits: null,
    latest_obits: null,

    contactPage: null,
    servicesPage: null,
    prepaidPage: null,
    informationPage: null,
    reviewsPage: null,
    privacyPage: null,

    homeID: "2dZQfOTBA",
    aboutID: "ABOUTID",
    contactID: "ISP6vPWvm",
    reviewsID: "jMtjAMYn9",
    galleryID: "XNtPZAHCD",
    servicesID: "1XwEo9imo",
    prepaidID: "RI7uHIzsx",
    historyID: "WTqL3sfu7",
    privacyID: "kFu86oN4q",
    obitsID: "AF01lcTH1",
    informationID: "LhA-zKYtR"
  },
  getters: {
    isHomePage(state) {
      return state.page.ItemID && state.page.ItemID == state.homeID
    },
    isObitsPage(state) {
      return state.page.ItemID && state.page.ItemID == state.obitsID
    },
    isReviewsPage(state) {
      return state.page.ItemID && state.page.ItemID == state.reviewsID
    },
    isGalleryPage(state) {
      return state.page.ItemID && state.page.ItemID == state.galleryID
    },
    isContactPage(state) {
      return state.page.ItemID && state.page.ItemID == state.contactID
    },
    isObit(state) {
      return state.page.SubItemType && state.page.SubItemType == "obituaries"
    }
  },
  mutations: {
    setPage(state, page) {
      state.page = page
    },
    setProp(state, payload) {
      state[payload.prop] = payload.value
    },
    setSettings(state, settings) {
      state.settings = settings
    },
    setMainPages(state, main_pages) {
      state.main_pages = main_pages
      state.menu_pages = main_pages.filter((p) => p.ItemID != state.homeID && p.ItemID != state.contactID && p.ItemID != state.privacyID)
      state.company_menu = main_pages.filter((p) =>
        [state.historyID, state.reviewsID, state.galleryID, state.contactID].includes(p.ItemID)
      )
      state.services_menu = main_pages.filter((p) =>
        [state.servicesID, state.prepaidID, state.informationID].includes(p.ItemID)
      )
    },
    setObits(state, obits) {
      state.obits = obits
    },
    setLatestObits(state, latest_obits) {
      state.latest_obits = latest_obits
    }
  },
  actions: {
    getSettings({ commit, state }) {
      return new Promise((resolve) => {
        if (!state.settings)
          api.getPageByItemID("SETTINGS").then((settings) => {
            commit("setSettings", settings)
            resolve()
          })
        else resolve()
      })
    },
    getMainPages({ commit, state }) {
      return new Promise((resolve) => {
        if (!state.main_pages)
          api.getPages("reviews", "page.main").then((main_pages) => {
            commit("setMainPages", main_pages)
            resolve()

            var promises = []

            main_pages.forEach((page) => {
              if (page.ItemID == state.contactID) commit("setProp", { prop: "contactPage", value: page })
              if (page.ItemID == state.servicesID) commit("setProp", { prop: "servicesPage", value: page })
              if (page.ItemID == state.prepaidID) commit("setProp", { prop: "prepaidPage", value: page })
              if (page.ItemID == state.informationID) commit("setProp", { prop: "informationPage", value: page })
              if (page.ItemID == state.reviewsID) commit("setProp", { prop: "reviewsPage", value: page })
              if (page.ItemID == state.privacyID) commit("setProp", { prop: "privacyPage", value: page })
            })

            Promise.all(promises).then(() => {
              commit("setMainPages", main_pages)
            })
          })
        else resolve()
      })
    },
    getObits({ commit, state }) {
      return new Promise((resolve) => {
        if (!state.obits)
          api
            .getPages(
              "ItemType,image,date_of_birth,date_of_death,date_of_funeral",
              "page.obituaries",
              "",
              "",
              "",
              "desc"
            )
            .then((obits) => {
              commit("setLatestObits", JSON.parse(JSON.stringify(obits.slice(0, 3))))
              commit("setObits", obits)
              resolve()
            })
        else resolve()
      })
    }
  }
})
